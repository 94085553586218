import axios from '@/axios.js'

const state = () => ({
    menuakses: [],
    menuitems: [],
})

const mutations = {
  SET_MENUROLES (state, payload) {
    state.menuitems = payload.menu
    state.menuakses = payload.akses
  },
  ADD_MENUROLE (state, item) {
    state.menuakses.unshift(item)
    const ItemIndex = state.menuitems.findIndex((p) => p.id === item.id)
    state.menuitems.splice(ItemIndex, 1)
  },
  REMOVE_MENUROLE (state, item) {
    state.menuitems.unshift(item)
    const ItemIndex = state.menuakses.findIndex((p) => p.id === item.id)
    state.menuakses.splice(ItemIndex, 1)
  },
}

const actions = {
  fetchMenuRole ({ commit }, nav_menu) {
    return new Promise((resolve, reject) => {
      axios.get(`/menuitem-role-list/${nav_menu}`)
        .then((response) => {
          commit('SET_MENUROLES', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  addMenuRole ({ commit }, menuitem ) {
    return new Promise((resolve, reject) => {
      axios.post('/menuitem-role-add', menuitem)
        .then((response) => {
          commit('ADD_MENUROLE', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeMenuRole ({ commit }, menuitem) {
    return new Promise((resolve, reject) => {
      axios.post('/menuitem-role-delete/', menuitem)
        .then((response) => {
          commit('REMOVE_MENUROLE', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  }
}

export default {
  isRegistered: false,
  namespaced: true,
  state,
  actions,
  mutations
}